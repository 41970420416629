import { combineReducers } from 'redux'

import menuReducer from './menuReducer'
import clientsReducer from './clientsReducer'
import WelcomePopupReducer from './welcomePopupReducer'
import currentSectionIndexReducer from './currentSectionIndexReducer'

const rootReducer = combineReducers({
  isMenuOpen: menuReducer,
  countClick: clientsReducer,
  welcomePopupIsOpen: WelcomePopupReducer,
  currentSectionIndex: currentSectionIndexReducer,
})

export default rootReducer
