// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-bodyshops-js": () => import("./../../../src/pages/portfolio/bodyshops.js" /* webpackChunkName: "component---src-pages-portfolio-bodyshops-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-lessonsaustralia-js": () => import("./../../../src/pages/portfolio/lessonsaustralia.js" /* webpackChunkName: "component---src-pages-portfolio-lessonsaustralia-js" */),
  "component---src-pages-portfolio-linguaschools-js": () => import("./../../../src/pages/portfolio/linguaschools.js" /* webpackChunkName: "component---src-pages-portfolio-linguaschools-js" */),
  "component---src-pages-portfolio-negina-js": () => import("./../../../src/pages/portfolio/negina.js" /* webpackChunkName: "component---src-pages-portfolio-negina-js" */),
  "component---src-pages-portfolio-powernapp-js": () => import("./../../../src/pages/portfolio/powernapp.js" /* webpackChunkName: "component---src-pages-portfolio-powernapp-js" */),
  "component---src-pages-portfolio-spp-js": () => import("./../../../src/pages/portfolio/spp.js" /* webpackChunkName: "component---src-pages-portfolio-spp-js" */),
  "component---src-pages-portfolio-traderadius-js": () => import("./../../../src/pages/portfolio/traderadius.js" /* webpackChunkName: "component---src-pages-portfolio-traderadius-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

